import { DateTime } from "luxon";

export function subtractOneHour(timeString: string): string {
  // Determine the format based on the input string
  const format = timeString.includes(":") && timeString.split(":").length === 3 ? "HH:mm:ss" : "HH:mm";

  // Parse the time string using the appropriate format
  const initialTime = DateTime.fromFormat(timeString, format);

  // Check if the time string is valid
  if (!initialTime.isValid) {
    throw new Error(`Invalid time string: ${timeString}`);
  }

  // Subtract one hour from the time
  const newTime = initialTime.minus({ hours: 1 });

  // Return the new time in the same format as the input
  return newTime.toFormat(format);
}

export function toFullTimeZoneName(timeZone : string) {
  const dateTime = DateTime.now().setZone(timeZone);
  const timeZoneName = dateTime.toFormat('ZZZZ'); // Gets the long time zone name
  const gmtOffset = dateTime.toFormat('ZZ'); // Gets the GMT offset

  if (!dateTime.isValid) {
    throw new Error(`Invalid time zone: ${timeZone}`);
  }
  return `${timeZoneName} ${gmtOffset}`;
};


export function currentUnixTimestamp(){
  return Math.floor(Date.now() / 1000);
}

export function unixTimestamp(date: string) {
  return Math.floor(new Date(date).getTime() / 1000);
}
